import React, { ReactElement } from "react";

export default function MultipleForkliftQuotesIcon(): ReactElement {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="800"
            height="800"
            fill="none"
            viewBox="0 0 800 800"
        >
            <path
                fill="#000"
                d="M91.832.044a9.282 9.282 0 00-7.55 9.294v669.398a9.289 9.289 0 009.294 9.294h83.676v102.271h.006c0 5.137 4.158 9.3 9.294 9.3h520.669c5.137 0 9.294-4.163 9.294-9.3V120.902a9.292 9.292 0 00-9.294-9.293h-83.675V9.338h-.007a9.289 9.289 0 00-9.294-9.294H93.576a8.713 8.713 0 00-1.744 0zm11.038 18.593h502.074v650.804H102.87V18.637zM353.907 74.42c-102.588 0-185.953 83.359-185.953 185.953 0 102.582 83.365 185.937 185.953 185.937 102.588 0 185.953-83.358 185.953-185.937 0-102.581-83.365-185.953-185.953-185.953zm0 18.594c92.541 0 167.358 74.816 167.358 167.341 0 92.535-74.817 167.342-167.358 167.342-92.542 0-167.358-74.817-167.358-167.342 0-92.535 74.816-167.341 167.358-167.341zM623.539 130.2h74.383v650.803H195.848v-92.97h418.395l.007-.006a9.289 9.289 0 009.293-9.294V130.205l-.004-.004zm-270.794 18.302h.013c-4.793.603-8.328 4.766-8.14 9.585v18.594c-27.381 0-46.488 21.584-46.488 46.488 0 26.15 24.061 46.488 55.789 46.488 24.392 0 37.188 12.835 37.188 27.895 0 16.305-9.489 27.895-27.895 27.895h-55.789l.006-.007a9.294 9.294 0 00-6.66 2.679 9.292 9.292 0 000 13.237 9.289 9.289 0 006.66 2.678h37.188v18.594a9.3 9.3 0 1018.6 0v-18.594c27.382 0 46.488-21.583 46.488-46.488 0-26.15-24.061-46.488-55.789-46.488-24.391 0-37.187-12.835-37.187-27.895 0-16.304 9.488-27.895 27.894-27.895h55.79l-.007.013a9.316 9.316 0 006.661-2.678 9.311 9.311 0 002.769-6.622 9.29 9.29 0 00-9.43-9.294h-37.187v-18.6a9.308 9.308 0 00-10.461-9.586l-.013.001zM184.822 520.675c-5.13.48-8.904 5.032-8.425 10.169.48 5.137 5.033 8.905 10.17 8.425h334.716a9.305 9.305 0 009.43-9.294 9.298 9.298 0 00-9.43-9.3H186.567a9.78 9.78 0 00-1.745 0zm0 74.376c-5.13.48-8.904 5.033-8.425 10.169.48 5.137 5.033 8.905 10.17 8.425h334.716a9.295 9.295 0 006.66-2.672 9.295 9.295 0 000-13.243 9.301 9.301 0 00-6.66-2.679H186.567a9.78 9.78 0 00-1.745 0z"
            ></path>
        </svg>
    );
}
