import React, { ReactElement } from "react";

export default function CompareForkliftBrandsIcon(): ReactElement {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="800"
            height="800"
            fill="none"
            viewBox="0 0 800 800"
        >
            <g fill="#000" clipPath="url(#clip0_491_215)">
                <path d="M613.086 273.291v33.374c0 3.54 1.406 6.937 3.909 9.44a13.352 13.352 0 009.44 3.909v41.955c0 5.96 3.951 11.192 9.678 12.831l40.049 11.443a13.26 13.26 0 007.342 0l40.048-11.443a13.343 13.343 0 009.679-12.854v-41.931c3.54 0 6.937-1.407 9.44-3.91a13.356 13.356 0 003.909-9.44v-33.373a57.442 57.442 0 0117.151-40.918 119.03 119.03 0 0036.247-85.902 120.159 120.159 0 00-53.238-99.924A120.137 120.137 0 00634.08 35.25a120.15 120.15 0 00-72.027 87.356c-7.92 39.316 4.297 79.996 32.569 108.434a60.267 60.267 0 0118.464 42.252zm93.446 78.547l-26.699 7.634-26.699-7.634v-31.824h53.398v31.824zm-118.97-220.457a94.24 94.24 0 0127.621-52.218 94.191 94.191 0 0153.291-25.489 93.446 93.446 0 0172.069 21.8 93.434 93.434 0 0132.592 67.873 93.416 93.416 0 01-28.04 69.876 84.223 84.223 0 00-25.214 60.073v20.024h-26.699V184.653a53.364 53.364 0 0028.815-18.934 53.371 53.371 0 0011.234-32.592h-26.699a26.697 26.697 0 01-40.048 23.123 26.698 26.698 0 01-13.35-23.123h-26.698a53.398 53.398 0 0040.048 51.501v108.69h-26.699v-20.024a86.753 86.753 0 00-26.233-61.126 93.096 93.096 0 01-25.99-80.788v.001z"></path>
                <path d="M559.688 413.452h106.795v54.321l-211.989 15.096a56.744 56.744 0 00-83.97-34.781 56.758 56.758 0 00-27.431 42.79l-210.585 15.03v-52.409h106.795V426.8H-.987v26.699H105.81v66.747a13.352 13.352 0 0013.35 13.349h.947l226.238-16.169a55.98 55.98 0 003.379 7.051L193.741 680.444h-7.825a53.416 53.416 0 00-37.759 15.637 53.421 53.421 0 00-15.638 37.76v26.699c0 3.54 1.406 6.937 3.909 9.44a13.352 13.352 0 009.44 3.909h507.278c3.54 0 6.937-1.406 9.44-3.909a13.352 13.352 0 003.909-9.44v-26.699a53.42 53.42 0 00-15.638-37.76 53.418 53.418 0 00-37.759-15.637h-7.825L449.291 524.461a55.997 55.997 0 005.792-14.821l225.72-16.085c7.002-.501 12.419-6.329 12.402-13.349v-66.747H800V386.76H559.71l-.022 26.692zM399.495 466.85a30.309 30.309 0 0121.46 8.88 30.329 30.329 0 018.897 21.459c0 8.046-3.194 15.77-8.892 21.46a30.34 30.34 0 01-51.793-21.472 30.343 30.343 0 018.892-21.43 30.383 30.383 0 0121.437-8.898l-.001.001zm240.289 266.988v13.35H159.205v-13.35c0-7.08 2.813-13.874 7.819-18.88a26.705 26.705 0 0118.88-7.819h427.181c7.08 0 13.874 2.813 18.88 7.819a26.705 26.705 0 017.819 18.88zm-408.309-53.397L367.52 544.396a56.876 56.876 0 0063.946 0l136.045 136.045H231.475z"></path>
                <path d="M162.882 414.78a13.344 13.344 0 009.679-12.778v-41.955c3.54 0 6.937-1.406 9.44-3.909a13.352 13.352 0 003.909-9.44v-33.374a57.401 57.401 0 0117.17-40.93 119.054 119.054 0 0036.228-85.889 120.158 120.158 0 00-53.238-99.924 120.137 120.137 0 00-112.66-11.3 120.15 120.15 0 00-72.027 87.356c-7.92 39.316 4.297 79.996 32.57 108.434a60.27 60.27 0 0118.463 42.253v33.373a13.352 13.352 0 0013.35 13.35v41.955c0 5.966 3.956 11.21 9.69 12.843l40.048 11.425v.005a13.2 13.2 0 007.33 0l40.048-11.495zM52.88 252.191a93.062 93.062 0 01-25.977-80.805 94.172 94.172 0 0180.902-77.718 93.424 93.424 0 0172.117 21.781 93.455 93.455 0 0132.611 67.91 93.452 93.452 0 01-28.087 69.899 84.24 84.24 0 00-25.233 60.072v20.024h-26.699v-95.34a53.393 53.393 0 0036.716-33.136 53.387 53.387 0 00-6.233-49.059 53.396 53.396 0 00-43.832-22.909 53.392 53.392 0 00-43.832 22.909 53.387 53.387 0 00-6.233 49.059 53.4 53.4 0 0036.716 33.136v95.34h-26.7V313.33a86.754 86.754 0 00-26.233-61.073l-.003-.066zm66.282-38.981a26.705 26.705 0 01-18.88-7.819 26.704 26.704 0 010-37.76 26.705 26.705 0 0137.76 0 26.705 26.705 0 010 37.76 26.705 26.705 0 01-18.88 7.819zm-26.7 178.729V360.05h53.399v31.889l-26.699 7.623-26.7-7.623z"></path>
            </g>
            <defs>
                <clipPath id="clip0_491_215">
                    <path fill="#fff" d="M0 0H800V800H0z"></path>
                </clipPath>
            </defs>
        </svg>
    );
}
