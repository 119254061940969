import React, { ReactElement } from "react";

export default function NewForkliftIcon(): ReactElement {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="800"
            height="800"
            fill="none"
            viewBox="0 0 800 800"
        >
            <path
                fill="#000"
                d="M674.137 241.694L408.396 122.041c-4.173-1.388-9.744-1.388-13.917 0L127.346 241.694c-5.561 2.785-8.346 6.959-8.346 12.52v315.828c0 5.561 4.173 11.132 8.346 13.917l265.742 119.653c2.785 1.388 4.173 1.388 6.958 1.388s5.561 0 6.959-1.388l265.741-119.653c5.561-2.785 8.346-8.346 8.346-13.917l-.01-315.828c1.398-5.561-1.387-9.735-6.958-12.52h.013zM384.744 667.436L150.998 561.699V277.871l233.736 105.737.01 283.828zm-214.268-413.22l228.176-101.564 1.388 1.388 1.387-2.785 151.651 66.78c2.786 1.388 26.438 12.52 30.609 15.305l45.914 20.866-55.648 25.039-1.388 1.407c-1.388 1.388-4.173 2.785-5.561 2.785l-165.569 73.74-230.959-102.961zm480.004 307.48L416.744 667.434V383.606L571.18 314.04c1.388-1.388 4.173-2.786 8.346-2.786l70.955-31.997-.001 282.439z"
            ></path>
        </svg>
    );
}
