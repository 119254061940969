import React, { ReactElement } from "react";

export default function ForkLiftEngineIcon(): ReactElement {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="800"
            height="800"
            fill="none"
            viewBox="0 0 800 800"
        >
            <g
                fill="#000"
                fillRule="evenodd"
                clipPath="url(#clip0_489_184)"
                clipRule="evenodd"
            >
                <path d="M0 278.656v285.573h28.476V278.656H0z"></path>
                <path d="M7.167 407.099v28.686H99.97v-28.686H7.167z"></path>
                <path d="M265.116 207.16c-10.013 0-19.312 5.332-24.465 13.923l-34.382 57.578h-91.96c-15.612 0-28.686 12.864-28.686 28.476v257.099c0 15.612 13.074 28.686 28.686 28.686h79.938c9.732 0 19.184 3.195 26.786 9.273l40.071 32.272c12.666 10.127 28.495 15.606 44.714 15.606H585.71c15.612 0 28.475-13.074 28.475-28.686v-85.633h46.194l11.391 46.2a14.283 14.283 0 0013.922 10.97h71.5c23.503 0 42.814-19.318 42.814-42.814V349.959c0-23.503-19.318-42.814-42.814-42.814h-71.5v-.006a14.279 14.279 0 00-13.922 10.759l-11.391 46.397h-46.194v-57.157c0-15.612-12.863-28.475-28.475-28.475h-77.617L473.5 221.084c-5.153-8.584-14.452-13.922-24.464-13.922h-49.147v28.476h49.147l38.809 64.541.006-.006a14.285 14.285 0 0012.233 6.971h85.632v71.5a14.294 14.294 0 004.184 10.153 14.277 14.277 0 0010.16 4.184h71.289a14.279 14.279 0 0013.916-10.97l11.602-46.193h60.319c8.062 0 14.344 6.071 14.344 14.133v200.15c0 8.056-6.282 14.133-14.344 14.133h-60.319l-11.602-46.187a14.28 14.28 0 00-13.916-10.969H600.06a14.305 14.305 0 00-10.16 4.183 14.31 14.31 0 00-4.184 10.16v99.976H305.825c-9.732 0-19.184-3.195-26.786-9.273l-40.288-32.068c-12.666-10.128-28.285-15.816-44.504-15.816h-79.938V307.141h99.976v-.007a14.292 14.292 0 0012.233-6.964l38.598-64.542h49.147v-28.476h-49.147v.008z"></path>
                <path d="M314.259 249.969v28.687h85.633v-28.687h-85.633zM242.764 150v28.686h228.641V150H242.764z"></path>
                <path d="M342.946 157.167v114.321h28.475V157.167h-28.475z"></path>
            </g>
            <defs>
                <clipPath id="clip0_489_184">
                    <path fill="#fff" d="M0 0H800V800H0z"></path>
                </clipPath>
            </defs>
        </svg>
    );
}
