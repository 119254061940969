import React, { ReactElement } from "react";

export default function FindForkliftIcon(): ReactElement {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="800"
            height="800"
            fill="none"
            viewBox="0 0 800 800"
        >
            <path
                fill="#000"
                d="M400.26 468.839c-129.731 0-235.26-104.487-235.26-232.92C165 107.491 270.532 3 400.26 3s235.26 104.487 235.26 232.919c0 128.428-105.532 232.92-235.26 232.92zm0-431.305c-110.674 0-200.726 88.993-200.726 198.385 0 109.39 90.057 198.386 200.726 198.386s200.726-88.993 200.726-198.386c0-109.389-90.057-198.385-200.726-198.385zM399.009 797.215c-41.615 0-75.471-33.569-75.471-74.846V577.026c0-9.545 7.722-17.267 17.267-17.267h116.423c9.544 0 17.267 7.721 17.267 17.267v145.343c0 41.277-33.856 74.846-75.484 74.846h-.002zm-40.938-202.923v128.079c0 22.226 18.362 40.313 40.938 40.313 22.577 0 40.959-18.092 40.959-40.313V594.292h-81.897z"
            ></path>
            <path
                fill="#000"
                d="M400.26 594.292c-9.544 0-17.267-7.721-17.267-17.266V451.565c0-9.544 7.722-17.267 17.267-17.267 9.545 0 17.267 7.722 17.267 17.267v125.461c0 9.544-7.722 17.266-17.267 17.266zM499.357 445.207c-9.545 0-17.267-7.722-17.267-17.267V238.372c0-9.544 7.721-17.267 17.267-17.267 9.545 0 17.266 7.722 17.266 17.267V427.94c0 9.544-7.721 17.267-17.266 17.267z"
            ></path>
            <path
                fill="#000"
                d="M400.26 468.838c-9.544 0-17.267-7.721-17.267-17.266V257.98c0-9.544 7.722-17.267 17.267-17.267 9.545 0 17.267 7.722 17.267 17.267v193.592c0 9.544-7.722 17.266-17.267 17.266zM301.163 445.239c-9.544 0-17.267-7.722-17.267-17.267V199.157c0-9.544 7.722-17.267 17.267-17.267 9.545 0 17.267 7.722 17.267 17.267v228.815c0 9.544-7.722 17.267-17.267 17.267z"
            ></path>
        </svg>
    );
}
