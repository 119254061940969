import React, { ReactElement } from "react";

export default function UsedForkliftIcon(): ReactElement {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="800"
            height="800"
            fill="none"
            viewBox="0 0 800 800"
        >
            <path
                fill="#000"
                d="M706.769 263.378l69.311-9.628c6.619-1.001 11.734-6.017 12.937-12.741 1.001-6.423-2.407-13.14-8.424-15.946L520.994 98.678c-2.609-1.407-5.814-2.008-8.83-1.407l-112.643 17.352-97.095-17.352a14.082 14.082 0 00-9.027 1.204L18.469 230.072c-6.018 2.806-9.432 9.432-8.23 16.149 1.204 6.619 6.62 11.734 13.344 12.538l74.93 7.424-59.481 93.689c-2.407 3.61-3.009 8.228-1.805 12.335 1.203 4.212 4.212 7.627 8.025 9.432l68.913 32.2v155.373c0 5.618 3.61 10.831 8.83 13.14l266.101 119.772c2.204 1 4.611 1.602 7.025 1.602 2.609 0 5.016-.602 7.22-1.602l265.814-119.668c5.619-2.407 9.026-7.424 9.026-13.14V394.177l102.21-48.55c4.611-2.407 8.025-7.025 8.424-12.336.602-5.212-1.805-10.432-6.017-13.538l-76.029-56.375zM512.066 127.863l210.752 102.811-56.877 8.025h-.203l-222.79-100.307 69.118-10.529zm-99.202 30.094l136.418 60.082c3.407 1.203 25.777 12.335 30.19 14.945l46.145 20.767-55.869 25.176-2.204 1.203c-1.407 1.204-3.408 2.204-6.018 2.61l-148.753 66.905-.014-191.694.105.006zm-332.715 75.93l221.678-106.023 50.958 9.027-226.103 101.811c-.203-.203-.602-.203-.805-.203l-45.728-4.612zm-6.416 127.595l58.479-92.079c1.602.805 3.008 2.008 4.61 3.212l239.233 108.031-51.357 97.697L73.733 361.482zm306.527 303.93L146.258 559.984V428.786l178.547 83.255c2.204.805 4.212 1.406 6.423 1.406 5.416 0 10.635-3.008 13.539-8.228l35.509-67.709-.016 227.902zm2.414-314.355l-215.857-97.298 215.857-95.697v192.995zm263.611 208.925l-234.02 105.429V414.643l57.277 66.505c4.415 5.017 11.937 6.619 17.953 3.813l158.787-75.531.003 150.552zM485.092 452.756l-63.896-74.328 146.151-65.903c1.805-1.204 4.009-2.204 8.229-3.408l79.841-35.908 7.22-1.805 1.407-2.008 80.645 59.683-259.597 123.677z"
            ></path>
        </svg>
    );
}
