import React, { ReactElement } from "react";

export default function ForkliftRentalIcon(): ReactElement {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="800"
            height="800"
            fill="none"
            viewBox="0 0 800 800"
        >
            <path
                fill="#000"
                d="M104.39 654.524c10.784 15.674 28.179 24.102 45.879 24.102 10.867 0 21.845-3.179 31.475-9.802 25.321-17.395 31.722-52.038 14.316-77.365-10.79-15.669-28.185-24.096-45.885-24.096-3.93 0-7.848.568-11.734 1.413a129.847 129.847 0 01-6.76-41.294c0-35.735 14.443-67.794 37.226-89.936 10.784 8.052 23.781 12.373 36.999 12.373 12.07 0 24.273-3.532 34.974-10.9 28.13-19.328 35.238-57.817 15.912-85.958-11.988-17.406-31.321-26.766-50.985-26.766-12.075 0-24.278 3.532-34.979 10.889-26.778 18.399-34.45 54.168-18.433 81.818-27.861 26.679-45.438 65.416-45.438 108.48 0 17.892 3.152 35.409 8.985 51.784-22.953 17.964-28.295 50.906-11.551 75.257l-.001.001zm122.472-235.876c-6.215 4.288-13.456 6.545-20.956 6.545-5.712 0-11.22-1.352-16.209-3.764 14.388-8.559 30.729-13.615 48.048-14.487-2.66 4.509-6.281 8.554-10.884 11.706h.001zm-57.464-37.292c1.799-9.741 7.279-18.191 15.443-23.798 6.214-4.278 13.46-6.54 20.966-6.54 12.241 0 23.694 6.01 30.603 16.048 3.162 4.614 5.105 9.692 5.971 14.873-25.746.111-49.82 7.92-70.322 21.375a36.793 36.793 0 01-2.66-21.96l-.001.002zm-19.222 210.727c10.205 0 19.742 5.011 25.504 13.378 4.652 6.766 6.39 14.934 4.939 23.014-.706-1.049-1.54-2.042-2.588-2.865-11.684-9.128-21.43-20.552-28.952-33.439.369-.017.734-.088 1.098-.088h-.001zm12.628 53.01c1.821 1.424 3.946 2.103 6.081 2.373-.402.309-.734.684-1.164.982-5.182 3.56-11.215 5.448-17.456 5.448a30.958 30.958 0 01-25.503-13.389c-4.68-6.805-6.43-15.023-4.929-23.141.999-5.42 3.483-10.288 6.971-14.366 9.211 16.236 21.352 30.642 35.999 42.093h.001z"
            ></path>
            <path
                fill="#000"
                d="M91.138 471.644a174.598 174.598 0 0111.766-27.611C62.892 372.932 82.528 281.06 150.02 233.871c26.094-18.246 56.526-27.881 88.022-27.881 28.865 0 57.111 8.09 81.676 23.384a24.708 24.708 0 0013.069 3.73c4.967 0 9.928-1.495 14.172-4.47L628.202 31.838c1.473-.933 5.806-2.24 7.991-2.24.613.043 1.225.065 1.838.065h.016l70.831-.05-20.669 83.9-10.767 7.528-48.578-10.458a24.777 24.777 0 00-5.205-.558 24.74 24.74 0 00-13.747 4.172 24.714 24.714 0 00-10.58 16.127l-6.954 38.219-39.693-11.319a24.675 24.675 0 00-6.783-.944 24.724 24.724 0 00-14.763 4.89 24.697 24.697 0 00-9.961 19.504l-.453 33.903-34.091-8.952a25.084 25.084 0 00-6.28-.811 24.763 24.763 0 00-13.511 4.018 24.773 24.773 0 00-10.911 16.81l-9.498 59.626-43.781 30.608c-7.732 5.409-11.723 14.748-10.26 24.068 9.404 60.356-16.447 121.677-65.846 156.22-26.105 18.273-56.569 27.926-88.094 27.926-28.164 0-55.306-7.765-78.905-21.822-2.004 8.025-3.129 16.463-3.129 25.211 0 .475.055.944.066 1.43 25.448 13.096 53.589 19.907 81.968 19.907 35.316 0 70.973-10.492 102.266-32.369 59.323-41.48 86.67-112.53 76.112-180.294l52.38-36.624 11.16-69.985 51.928 13.637c1.059.27 2.091.403 3.074.403 5.563 0 9.752-4.172 9.84-10.442l.701-52.137 53.252 15.188c1.164.331 2.318.486 3.444.486 5.634 0 10.502-3.974 11.606-10.028l9.443-51.9 52.695 11.341c.767.16 1.628.243 2.511.243 3.229 0 6.993-1.015 9.333-2.643l16.247-11.369c2.986-2.098 6.182-6.678 7.109-10.238l25.454-103.32c1.893-7.148-2.467-13.356-9.697-13.897l-89.28.066a23.017 23.017 0 00-1.777-.066c-7.119 0-16.65 2.82-22.159 6.661L332.782 208.368c-28.627-17.826-61.526-27.114-94.739-27.126-35.283 0-70.918 10.475-102.189 32.348-82.255 57.535-103.066 171.958-46.502 255.585.573.844 1.197 1.633 1.782 2.467l.004.002z"
            ></path>
            <path
                fill="#000"
                d="M706.548 449.914H425.659a211.528 211.528 0 01-12.865 24.725h293.761c13.637 0 24.725 11.093 24.725 24.725v247.249c0 13.632-11.088 24.725-24.725 24.725H207.606c-19.626 0-48.595-14.007-60.785-29.383l-70.113-88.474c-9.951-12.572-10.415-35.819-.96-48.772l15.618-21.397c-3.416-10.304-5.822-20.994-7.334-31.883l-28.257 38.705c-16.039 21.966-15.337 57.381 1.55 78.695l70.113 88.474c16.888 21.314 52.96 38.755 80.163 38.755H706.55c27.197 0 49.45-22.253 49.45-49.45V499.359c0-27.198-22.253-49.45-49.45-49.45l-.002.005z"
            ></path>
        </svg>
    );
}
